interface AcquisitionParams {
  corp_id: string
  limit: number
  cursor?: string
}

export interface FollowUserParams {
  corpId: string
}

export interface CreateCustomerAcquistionTypes {
  corpId: string
  linkName: string
  adsetId?: number
  range: {
    userList: string[]
    departmentList: number[]
  }
  state?: string
  skipVerify: boolean
}

export interface UpdateCustomerAcquisition {
  corpId: string;
  linkId: string;
  linkName: string;
  adsetId?: number;
  range: {
    userList: string[];
    departmentList: number[];
  };
  state?: string;
  skipVerify: boolean;
};

export interface CopyWxUsersParams {
  corpId: string;
  source_link_id: string;
  target_link_id: string;
}


export const listCustomerAcquisitions = (corp_id: string, enterprise_id?: number, page?: number, limit?: number) => {
  const params = { enterprise_id, corp_id, page, limit }

  if (!enterprise_id) {
    delete params.enterprise_id;
  }
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + '/api/v1/wxwork/list_corp_customer_acquisition?' + new URLSearchParams(params as any)).then(res => {
    return res.json()
  })
  return response
}

export const followUserAndDeparts = (data: FollowUserParams) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/wxwork/follow_user_and_departs`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}

export const createCustomerAcquistion = async (data: CreateCustomerAcquistionTypes) => {
  try {
    const res = await fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/wxwork/create_customer_acquisition`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    return await res.json();
  } catch (error) {
    throw error;
  }
}

export const updateCustomerAcquistion = (data: UpdateCustomerAcquisition) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/wxwork/update_customer_acquisition`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}

export const copyWxUsers = (data: CopyWxUsersParams) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/wxwork/customer_acquisition/copy_users`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}

export const getCustomerAcquistion = (linkId: string, corpId: string) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/wxwork/customer_acquisition/${linkId}?corp_id=${corpId}`).then(res => {
    return res.json()
  })
  return response
}

export const getAuthCorp = (corpId: string) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/wxwork/auth_corp/${corpId}`).then(res => {
    return res.json()
  })
  return response
}
