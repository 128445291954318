import { Page } from 'redux/common';
import { CustomerAcquisitionsActionTypes } from './constants'

export type CustomerAcquisitionsActionType = {
  type:
  | CustomerAcquisitionsActionTypes.API_RESPONSE_SUCCESS
  | CustomerAcquisitionsActionTypes.API_RESPONSE_ERROR
  | CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS
  | CustomerAcquisitionsActionTypes.GET_CUSTOMERACQUISITION
  | CustomerAcquisitionsActionTypes.GET_AUTH_CORP
  | CustomerAcquisitionsActionTypes.RESET

  payload: {} | string;
};

export interface CustomerAcquisition {
  id: number
  corpId: string
  openKfid: string
  name: string
  avatar: string
  scene: string
  kfUrl: string
  mediaId: string
  enterpriseId: number
}

export const customerAcquisitionApiResponseSuccess = (actionType: string, data: CustomerAcquisition | {}, page?: Page): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const customerAcquisitionApiResponseError = (actionType: string, error: Error): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listCustomerAcquisition = (corp_id: string, enterpriseId?: number, page?: number, pageSize?: number): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS,
  payload: {
    corp_id,
    enterpriseId,
    page,
    pageSize
  },
})

export const getCustomerAcquisition = (linkId: string, corpId: string): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.GET_CUSTOMERACQUISITION,
  payload: {
    linkId,
    corpId
  },
})

export const resetCustomerAcquisition = (): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.RESET,
  payload: {},
})

export const getAuthCorp = (corp_id: string): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.GET_AUTH_CORP,
  payload: {
    corp_id
  },
})
