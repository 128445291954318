import { Page } from 'redux/common';
import { WxUserActionTypes } from './constants';

export type WxUserActionType = {
  type:
  | WxUserActionTypes.API_RESPONSE_SUCCESS
  | WxUserActionTypes.API_RESPONSE_ERROR
  | WxUserActionTypes.LIST_WXUSERS
  | WxUserActionTypes.RESET

  payload: {} | string;
};

export interface WxUser {
  id: number
  corpId: string
  openKfid: string
  name: string
  avatar: string
  scene: string
  kfUrl: string
  mediaId: string
  enterpriseId: number
}

export const wxUserApiResponseSuccess = (actionType: string, data: WxUser | {}, page?: Page): WxUserActionType => ({
  type: WxUserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const wxUserApiResponseError = (actionType: string, error: Error): WxUserActionType => ({
  type: WxUserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listWxUsers = (corp_id: string, page: number = 1, pageSize: number = 20): WxUserActionType => ({
  type: WxUserActionTypes.LIST_WXUSERS,
  payload: {
    corp_id,
    page,
    pageSize,
  },
})

export const resetWxUser = (): WxUserActionType => ({
  type: WxUserActionTypes.RESET,
  payload: {},
})