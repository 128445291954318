import { all } from 'redux-saga/effects';
import adsetSaga from './adset/saga';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import settingSaga from './setting/saga';
import trackSaga from './track/saga';
import customerSaga from './customer/saga';
import accountSaga from './account/saga';
import thirdpartAppSaga from './thirdpartyapp/saga';
import formsSaga from './forms/saga';
import formSchemasSaga from './formSchema/saga';
import ordersSaga from './orders/saga';
import productSaga from './product/saga';
import userSaga from './user/saga';
import enterpriseSaga from './enterprise/saga';
import extOrdersSaga from './extOrders/saga';
import serviceSaga from './service/saga';
import wxCustomerSaga from './wxCustomer/saga'
import authorizedEnterpriseSaga from './authorizedEnterprise/saga'
import customerAcquisitionsSaga from './customerAcquisitions/saga'
import yianOrdersSaga from './yianOrders/saga'
import wxUserSaga from './wxUsers/saga'

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        settingSaga(),
        adsetSaga(),
        trackSaga(),
        accountSaga(),
        customerSaga(),
        thirdpartAppSaga(),
        formsSaga(),
        formSchemasSaga(),
        ordersSaga(),
        productSaga(),
        userSaga(),
        enterpriseSaga(),
        extOrdersSaga(),
        serviceSaga(),
        wxCustomerSaga(),
        authorizedEnterpriseSaga(),
        customerAcquisitionsSaga(),
        yianOrdersSaga(),
        wxUserSaga()
    ]);
}
